import React from 'react';
import { Blank } from 'grommet-icons';

const IconCheckCircle = (props) => {
  return (
    <Blank
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 37 37"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      {...props}
    >
      <path d="M18.949.031c-9.934 0-18 8.066-18 18 0 9.935 8.066 18 18 18 9.935 0 18-8.065 18-18 0-9.934-8.065-18-18-18zm0 2c8.831 0 16 7.17 16 16 0 8.831-7.169 16-16 16-8.83 0-16-7.169-16-16 0-8.83 7.17-16 16-16z" />
      <path d="M9.045 20.825l7.566 5.98 12.249-14.14-1.512-1.31-10.997 12.696-6.066-4.795-1.24 1.569z" />
    </Blank>
  );
};

export default IconCheckCircle;
